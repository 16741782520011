<template>
  <div class="harmbox">
    <dv-border-box-10 :color="['#2E6099', '#0DE7C2']">
      <div class="harmlist">
        <div class="top">
          <div class="title">
            <p>{{ langObj.detail.damage_list }}</p>
          </div>
          <div class="form">
            <div class="item">
              <el-form-item :label="langObj.detail.value_filtering">
                <el-input
                  v-model="ruleForm.filter_value"
                  :placeholder="langObj.detail.value_filtering"
                  @input="handleBlurFilterValue"
                />
              </el-form-item>
            </div>
            <div class="item">
              <div class="item">
                <el-form-item :label="langObj.detail.grade_filter">
                  <el-select
                    v-model="ruleForm.filter_level"
                    :placeholder="langObj.detail.grade_filter"
                  >
                    <el-option
                      :label="langObj.detail.no_filtering"
                      :value="0"
                    />
                    <el-option :label="langObj.detail.Minor" :value="1" />
                    <el-option :label="langObj.detail.Medium" :value="2" />
                    <el-option :label="langObj.detail.Serious" :value="3" />
                    <el-option :label="langObj.detail.Severe" :value="4" />
                    <el-option :label="langObj.detail.Over_limit" :value="5" />
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="list-box">
          <el-table
            :data="harmList"
            ref="tableRef"
            height="3.9rem"
            style="width: 98%; margin: 0 auto"
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick"
            @row-dblclick="changeStationFlawDlalog"
            @sort-change="handleSortChangle"
          >
            <el-table-column type="index" width="30" />
            <el-table-column type="selection" width="30" />
            <el-table-column
              prop="ropeNum"
              :label="langObj.detail.rope_num"
              width="80"
            />
            <el-table-column
              prop="damageSite"
              :label="langObj.detail.damage_location"
              sortable="custom"
              width="150"
            />
            <el-table-column
              prop="damageValue"
              :label="langObj.detail.damage_value"
              sortable
              width="150"
            />
            <el-table-column
              prop="damageLevel"
              :label="langObj.detail.damage_level"
            >
              <template #default="scope">
                <span
                  class="back-color"
                  :class="[filterDamageLevelBackColor(scope.row)]"
                  >{{ filterDamageLevelText(scope.row, lang) }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="diameterMeter1"
              :label="langObj.detail.visual_measurement"
              width="150"
            />
            <!-- <el-table-column prop="damageFloor" :label="langObj.detail.floor_position" width="100" /> -->
          </el-table>
        </div>
      </div>
    </dv-border-box-10>
    <div class="station-flaw-box">
      <el-dialog
        v-model="dialogVisible"
        title="损伤趋势图"
        width="70%"
        :close-on-click-modal="false"
      >
        <div>
          <stationFlaw
            :stationFlowRule="stationFlowRule"
            :isDestory="isDestory"
          ></stationFlaw>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import {
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
  computed,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import {
  httpGetStationFlawDataHook,
  filterDamageLevelTextHook,
  // filterDamageLevelColorHook,
  filterDamageLevelBackColorHook,
} from "@/hooks/detail/detail.js"; //定义 ruleFrom rules

import stationFlaw from "@/components/detail/harm/stationFlaw/stationFlaw";
import { useStore } from "vuex";
//  import { debounce } from "@/common/scrollToSlow.js";

const ruleForm = reactive({
  id: 0,
  filter_value: "",
  filter_level: 0,
  sort: 0,
  reverse: 0,
});

const props = defineProps({
  id: [Number, String],
});

const emitFun = defineEmits(["filterFlawDataRule", "getStationFlawData"]);

const store = useStore();

let tableRef = ref(null);
let dialogVisible = ref(false);
let isDestory = ref(false);
const harmList = reactive([]);
const stationFlowRule = reactive({
  id: 0,
  fid: 0,
  rope: 0,
  pos: 0,
  sort: 0,
});
let timer = ref(null);

const langObj = computed(() => {
  return store.state.lang.list;
});

const lang = computed(() => {
  return store.state.lang.lang;
});

const httpGetStationFlawData = async () => {
  const harmListRes = await httpGetStationFlawDataHook(ruleForm);
  harmList.length = 0;
  harmList.push(...harmListRes);
  emitFun("getStationFlawData", harmList);
};

const handleSelectionChange = () => {
  //console.log(row);
};

const handleRowClick = (row) => {
  tableRef.value.toggleRowSelection(row);
};

const changeStationFlawDlalog = (row) => {
  stationFlowRule.id = ruleForm.id;
  stationFlowRule.fid = row.id;
  stationFlowRule.rope = row.ropeNum;
  stationFlowRule.pos = row.damageSite;
  dialogVisible.value = true;
};

//const filterDamageLevelText = filterDamageLevelTextHook
const filterDamageLevelText = (row, lang) => {
  return filterDamageLevelTextHook(row.damageLevel, lang);
};

// const filterDamageLevelColor = (row) => {
//   return filterDamageLevelColorHook(row.damageLevel);
// };

const filterDamageLevelBackColor = (row) => {
  return filterDamageLevelBackColorHook(row.damageLevel);
};

const handleBlurFilterValue = function () {
  //函数防抖必须利用watch 不能用@input
  //let filter_value = e.target.value;
  // httpGetStationFlawData();
};

const debounce = function (fn, wait = 300) {
  if (timer.value !== null) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(fn, wait);
};

const handleSortChangle = ({ prop, order }) => {
  if (prop == "damageSite") {
    ruleForm.reverse = order == "descending" ? 1 : 0;
    httpGetStationFlawData();
  }
};

watch(
  () => ruleForm.filter_level,
  () => {
    httpGetStationFlawData();
    emitFun("filterFlawDataRule", ruleForm);
  },
  { deep: true, immediate: false }
);

watch(
  () => ruleForm.filter_value,
  () => {
    emitFun("filterFlawDataRule", ruleForm);
    debounce(function () {
      httpGetStationFlawData();
    });
  },
  { deep: true, immediate: false }
);

watch(
  () => props.id,
  (id) => {
    ruleForm.id = id;
    httpGetStationFlawData();
  },
  { deep: true, immediate: false }
);

watch(
  () => store.state.newTimes,
  (newTimes, oldTimes) => {
    if (oldTimes !== 0) {
      harmList.length = 0;
      httpGetStationFlawData();
    }
    //在这把损伤列表harmList 置空 界面才会刷新一下
  },
  { deep: true, immediate: false }
);

onMounted(() => {
  nextTick(() => {});
});

onBeforeUnmount(() => {
  isDestory.value = true;
  console.log(isDestory);
});

onUnmounted(() => {
  // isDestory.value = true;
  // console.log(isDestory)
});
</script>

<style lang="scss" scoped>
.harmbox {
  height: 100%;
  .harmlist {
    height: 100%;
    padding: 0.25rem 0.125rem;
    .top {
      display: flex;
      justify-content: flex-start;

      .title {
        width: 20%;
        padding-left: 10px;
        display: flex;
        align-items: center;
        p {
          font-size: 0.225rem;
        }
      }
      .form {
        width: 70%;
        height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .item {
          display: flex;
          align-items: center;
          color: #fff;
          margin-right: 0.25rem;
          & :deep .el-form-item {
            margin-bottom: 0;
          }
          & :deep .el-form-item__label {
            color: #fff;
            font-size: 0.2rem;
          }
          & :deep .el-input__inner {
            font-size: 0.175rem;
          }
        }
      }
    }
    .list-box {
      box-shadow: 0 0 1px #fff;

      & :deep .el-table {
        font-size: 0.1625rem;
        background-color: transparent;
      }
      & :deep .el-table tr,
      & :deep .el-table th.el-table__cell {
        background-color: transparent;
        color: #fff;
      }
      & :deep .el-table .cell {
        padding: 0;
      }
      & :deep .el-table__body tr:hover > td.el-table__cell {
        color: #333;
      }
      & :deep .el-table__cell {
        text-align: center;
      }
      & :deep .el-table__header th {
        font-weight: 600;
        color: #333;
        font-size: 0.15rem;
      }

      & :deep .el-table__body-wrapper {
        background-color: transparent;
      }

      & :deep .el-table__header-wrapper {
        background-color: transparent;
      }

      & :deep .el-table__inner-wrapper::before {
        height: 0;
      }
      & :deep .back-color {
        // background-color: #F0F0F0;
        display: block;
        padding: 0;
        width: 40px;
        margin: 0 auto;
        color: #333;
        border-radius: 5px;
      }
    }
  }
  & :deep .el-dialog {
    background-color: #021e33;
  }
  & :deep .el-dialog__title {
    color: #fff;
  }
}
</style>
