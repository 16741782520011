<template>
  <div class="week-box">
    <el-scrollbar height="5.8rem">
      <div class="list-box">
        <div
          class="list-item"
          v-for="(item, index) in statisticsList"
          :key="index"
          @dblclick="handleDbclickItem(item, index)"
        >
          <div class="date-box">
            <p class="date">
              <span>[</span> {{ item.startDate }} ~ {{ item.endDate }}
              <span>]</span>
            </p>

            <p :class="[filterWireRopeSafeStateColor(item)]">
              {{ langObj.detail.rope_state }}：<span>{{
                filterWireRopeSafeState(item)
              }}</span>
            </p>
          </div>
          <div class="run-box">
            <p>
              {{ langObj.detail.forward_running }}：{{ item.runNum }}
              {{ langObj.detail.times }}
            </p>
            <p>
              {{ langObj.detail.total_duration }}：{{
                item.runTime.toFixed(1)
              }}h
            </p>
            <p>
              {{ langObj.detail.total_mileage }}：{{
                item.runLength.toFixed(2)
              }}km
            </p>
          </div>
          <div class="flaw-box">
            <div class="flaw">
              <p :class="{ en: lang == 'en' }">
                {{ langObj.detail.damage_num }}:
              </p>
              <p>
                <span>{{ langObj.detail.Slight }} {{ item.qingwei }}</span
                ><span>{{ langObj.detail.Minor }} {{ item.qingdu }}</span
                ><span>{{ langObj.detail.Medium }} {{ item.zhongdu }}</span
                ><span>{{ langObj.detail.Serious }} {{ item.jiaozhong }}</span
                ><span>{{ langObj.detail.Severe }} {{ item.yanzhong }}</span
                ><span
                  >{{ langObj.detail.Over_limit }} {{ item.chaoxian }}</span
                >
                <!-- <span class="yujing" v-show="item.yujing > 0"
                  >预警 {{ item.yujing }}</span
                ><span class="baojing" v-show="item.baojing > 0"
                  >报警 {{ item.baojing }}</span
                > -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <div class="report-dlalog">
      <el-dialog
        v-model="dialogVisible"
        :title="langObj.report.test_report"
        :width="reportDialogWidth"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="closeDialog"
        @close="handleCloseDialog"
      >
        <div>
          <reportDialog
            :id="stationId"
            :statistics="statistics"
            :allRunLength="clickAllRunLength"
            :allRunTime="clickAllRunTime"
            :allWeekRunLength="allWeekRunLength"
            :allWeekRunTime="allWeekRunTime"
            :clickReport="clickReport"
            @changeDialogClose="changeDialogClose"
          ></reportDialog>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import {
  GetStationStatistics,
  // GetStationFlawDaily,
} from "@/common/api/index/api.js";
import {
  nextTick,
  onMounted,
  ref,
  reactive,
  watch,
  toRefs,
  computed,
} from "vue";
import reportDialog from "@/components/detail/report/reportDialog";
// import { ElMessage } from "element-plus";
import { useStore } from "vuex";
const ruleForm = reactive({
  id: 0,
  unit: 1,
  period: 0,
});

const props = defineProps({
  id: [Number, String],
  clickReport: Boolean,
});

const store = useStore();
const closeDialog = ref(false); //是否显示dialog 关闭按钮

const emitFun = defineEmits([
  "getAllWeekLengthAndTime",
  "changeReportDialog",
  "changeLoadDetail",
]);
let stationId = ref(0);
let reportDialogWidth = ref("70%");
const statisticsList = reactive([]);
let dialogVisible = ref(false);
let clickAllRunLength = ref(0); //点击当前周之前里程
let clickAllRunTime = ref(0); //点击当前周之前的时长
let allWeekRunLength = ref(0); //所有周的总里程
let allWeekRunTime = ref(0); //所有周的总时长
let statisticsDbclick = reactive({
  statistics: {},
});

const { statistics } = toRefs(statisticsDbclick);

const ruleFormDaily = reactive({
  id: 0,
  sdate: "",
  edate: "",
  sort: 0,
});

const langObj = computed(() => {
  return store.state.lang.list;
});

const lang = computed(() => {
  return store.state.lang.lang;
});

const httpGetStationFlawTrend = async (ruleForm) => {
  const res = await GetStationStatistics(ruleForm);
  const statisticsData = res.data.data;
  const statisticsArr = [];
  for (let i = 0; i < statisticsData.length; i++) {
    const tempObj = {};
    tempObj.startDate = statisticsData[i][0];
    tempObj.endDate = statisticsData[i][1];
    tempObj.qingwei = statisticsData[i][2];
    tempObj.qingdu = statisticsData[i][3];
    tempObj.zhongdu = statisticsData[i][4];
    tempObj.jiaozhong = statisticsData[i][5];
    tempObj.yanzhong = statisticsData[i][6];
    tempObj.chaoxian = statisticsData[i][7];
    tempObj.yujing = statisticsData[i][8];
    tempObj.baojing = statisticsData[i][9];
    tempObj.runNum = statisticsData[i][10];
    tempObj.runTime = statisticsData[i][11];
    tempObj.runLength = statisticsData[i][12];
    statisticsArr.push(tempObj);
  }
  statisticsList.push(...statisticsArr);

  emitFun("changeLoadDetail", false);
  computedAllWeekRunLength(statisticsList); //计算获取所有周的总里程和时长
};

// const httpGetStationFlawDaily = async (ruleFormDaily) => {
//   const res = await GetStationFlawDaily(ruleFormDaily);
//   return res.data.data;
// };

const filterWireRopeSafeState = (item) => {
  if (lang.value == "zh-CN") {
    if (item.yujing > 0 && item.baojing > 0) {
      return "报警";
    } else if (item.yujing > 0) {
      return "预警";
    } else {
      return "健康";
    }
  } else if (lang.value == "en") {
    if (item.yujing > 0 && item.baojing > 0) {
      return "alarm";
    } else if (item.yujing > 0) {
      return "warning";
    } else {
      return "health";
    }
  }
};

const filterWireRopeSafeStateColor = (item) => {
  if (item.yujing > 0 && item.baojing > 0) {
    return "baojing";
  } else if (item.yujing > 0) {
    return "yujing";
  } else {
    return "jiankang";
  }
};

const handleDbclickItem = async (item, index) => {
  if (ruleFormDaily.sdate != item.startDate) {
    closeDialog.value = false; // 不显示关闭按钮
  }

  ruleFormDaily.id = props.id;
  ruleFormDaily.sdate = item.startDate;
  ruleFormDaily.edate = item.endDate;
  dialogVisible.value = true;

  statisticsDbclick.statistics = item;
  computedCurrentAllWeekRunLength(statisticsList, index);

  // const flawDailyData = await httpGetStationFlawDaily(ruleFormDaily);

  // if (flawDailyData.length == 0) {
  //   ElMessage({
  //     message: "此周检测未运行",
  //     type: "warning",
  //     offset: 150,
  //     // duration:2000,
  //     onClose: () => {
  //       dialogVisible.value = false;
  //     },
  //   });
  // } else {
  // }
};

const computedAllWeekRunLength = (statisticsList) => {
  let allRunLengthTmp = 0;
  let allRunTimeTmp = 0;
  for (let i = 0; i < statisticsList.length; i++) {
    allRunLengthTmp += statisticsList[i].runLength;
    allRunTimeTmp += statisticsList[i].runTime;
  }
  allWeekRunLength.value = parseFloat(allRunLengthTmp.toFixed(2));
  allWeekRunTime.value = parseFloat(allRunTimeTmp.toFixed(1));

  emitFun("getAllWeekLengthAndTime", {
    allWeekRunLength,
    allWeekRunTime,
  });
};

const computedCurrentAllWeekRunLength = (statisticsList, index) => {
  let allRunLengthTmp = 0;
  let allRunTimeTmp = 0;

  for (let i = 0; i < statisticsList.length; i++) {
    if (i >= index) {
      allRunLengthTmp += statisticsList[i].runLength;
      allRunTimeTmp += statisticsList[i].runTime;
    }
  }
  clickAllRunLength.value = parseFloat(allRunLengthTmp);
  clickAllRunTime.value = parseFloat(allRunTimeTmp);
  console.log(allRunLengthTmp);
  console.log(index);
};

const handleCloseDialog = () => {
  emitFun("changeReportDialog", false);
};

const changeDialogClose = (flag) => {
  closeDialog.value = flag;
};

watch(
  () => props.id,
  async (id) => {
    stationId.value = id;
    ruleForm.id = id;
    await httpGetStationFlawTrend(ruleForm);
  },
  { deep: true, immediate: false }
);

watch(
  () => props.clickReport,
  (flag) => {
    if (flag) dialogVisible.value = true;
  },
  { deep: true, immediate: false }
);

onMounted(() => {
  nextTick(() => {
    if (screen.width <= 1440 || window.innerWidth <= 1440) {
      reportDialogWidth.value = "90%";
    }
  });
});
</script>

<style lang="scss" scoped>
.week-box {
  padding: 0 0.25rem 0.25rem 0.25rem;

  .list-box {
    .list-item {
      font-size: 0.2rem;
      padding: 0.25rem 0;
      cursor: pointer;
      .date-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p.date {
          color: #14ccb1;
          font-weight: 600;
        }

        p.state {
          color: #08f00e;
          margin-right: 0.25rem;
        }
      }

      .run-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.1875rem;
      }

      .flaw-box {
        margin-top: 0.1875rem;

        .flaw {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          & p:nth-of-type(1) {
            margin-right: 0.25rem;
          }

          & p.en {
            margin-right: 0.15rem;
          }

          & p:nth-of-type(2) {
            span {
              margin-right: 0.125rem;
            }
          }

          .yujing {
            color: #ffff00;
          }

          .baojing {
            color: #ff0000;
          }
        }
      }
    }
  }

  & :deep .el-dialog {
    background-color: #021e33;
  }

  & :deep .el-dialog__header {
    text-align: center;
  }

  & :deep .el-dialog__header span {
    color: #fff;
  }
}

@media screen and (max-width: 1366px) {
  .week-box {
    padding: 0 0.25rem 0.25rem 0.25rem;

    .list-box {
      .list-item {
        font-size: 0.2rem;
        padding: 20px 0;

        .date-box {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p.date {
            color: #14ccb1;
          }

          p.state {
            color: #08f00e;
          }
        }

        .run-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0.1875rem;
        }

        .flaw-box {
          margin-top: 0.1875rem;

          .flaw {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & p:nth-of-type(1) {
              margin-right: 0.125rem;
            }

            & p:nth-of-type(2) {
              span {
                margin-right: 0.125rem;
              }
            }

            .yujing {
              color: #ffff00;
            }

            .baojing {
              color: #ff0000;
            }
          }
        }
      }
    }
  }
}
</style>
