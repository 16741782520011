<template>
  <div class="wave-box" id="wave_box">
    <!-- <div class="rope-charts" v-show="cuverFlag">
      <div id="container" class="wave-antv"></div>
    </div> -->
    <div class="rope-charts" v-show="!cuverFlag">
      <div id="curverEcharts" ref="curverEcharts" class="wave-antv"></div>
    </div>
    <div
      class="tooltip-box"
      :style="tooltipStyle"
      v-show="tooTipVis && harmList.length > 0"
    >
      <ul>
        <!-- <div style="margin-left: 5px; margin-bottom: 10px">
          实时位置: {{ parseFloat(pointLength).toFixed(2) }}m
        </div> -->
        <div style="margin-left: 5px; margin-bottom: 10px">损伤数据:</div>
        <li v-for="(item, index) in pointDataArr" :key="index">
          <div class="flaw-box">
            <p>损伤: {{ item[2].toFixed(2) }}m</p>
            <p>量值: {{ item[3].toFixed(1) }}%</p>
            <p>
              等级:
              <span
                style="margin-left: 3px"
                :class="[filterDamageLevelColor(item[4])]"
              >
                {{ filterDamageLevelText(item[4]) }}</span
              >
            </p>
          </div>
        </li>
      </ul>
    </div>

    <div class="sensor-dialog">
      <el-dialog
        v-model="dialogVisible"
        title="传感器波形图"
        width="80%"
        :close-on-click-modal="false"
        @close="closeSensorDlalog"
      >
        <div>
          <waveSensor

            :echartsSencorData="echartsSencorData"
            :isDestory="isDestory"
            :sensorsNum="sensorsNum"
          ></waveSensor>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  nextTick,
  onMounted,
  reactive,
  watch,
  ref,
  onBeforeUnmount,
  toRefs,
} from "vue";

import { initCurverSencorEchatsOptionsDetail } from "@/hooks/detail/echarts/curver/curverEcharts.js";

import { httpGetSingleCurveHook } from "@/hooks/detail/cuver.js"; //引入封装的Antv G2 组件

import waveSensor from "@/components/detail/cuver/waveSensor";
// import { GetStationFlawData } from "@/common/api/index/api.js";
import {
  filterDamageLevelTextHook,
  filterDamageLevelColorHook,
} from "@/hooks/detail/detail.js"; //定义 ruleFrom rules

import { debounce } from "../../../common/scrollToSlow.js";
import { useStore } from "vuex";
import _ from "lodash";
export default {
  name: "",
  setup(props, context) {
    // 初始化图表实例
    let chart = null;
    let sencorChart = null;
    let chartRef = ref({});
    let imgSrc = ref("");
    let dialogVisible = ref(false);
    let isDestory = ref(false);
   
    let echartsRopeData = [];
    let echartsGroupData = [];
    let echartsSencorData = reactive([]);
    let sensorsNum = ref(0);
    const harmList = reactive([]);
    let tooTipVis = ref(false);
    let moveFlag = ref(true);

    let chartScale = {
      maxLength: 0,
      minLength: 0,
      maxValue: 0,
      minValue: 0,
      zoomScaleY: false,
    };
    const store = useStore();
    const curverEcharts = ref(null);
    let echartsObj = null;

    const hoverPoineData = reactive({
      pointDataArr: [],
      pointLength: "",
    });

    const tooltipStyle = reactive({
      left: "100px",
      top: "",
    });

    // const ruleForm = reactive({
    //   id: 0,
    //   filter_value: "",
    //   filter_level: 0,
    //   sort: 0,
    //   reverse: 0,
    // });

    // const httpGetStationFlawData = async (id) => {
    //   ruleForm.id = id;
    //   harmList.length = 0;
    //   const res = await GetStationFlawData(ruleForm);
    //   harmList.push(...res.data.flaw_list);
    // };

    const initEchartsCurveById = async (id) => {

      //获取钢丝绳和传感器数据
      const {
        echartsDataRope,
        echartsRopeDataGroup,
        sencorEchartsData,
        sensors,
      } = await httpGetSingleCurveHook(id);
      // ropeG2Data = ropeG2DataRt;

      echartsRopeData = echartsDataRope;
      echartsGroupData = echartsRopeDataGroup;
      echartsSencorData.length = 0;
      for (let i = 0; i < sencorEchartsData.length; i++) {
        echartsSencorData.push(sencorEchartsData[i]);
      }
      sensorsNum.value = sensors;

      if (echartsRopeData) {
        const echartsGroupDataSort = _.cloneDeep(echartsGroupData); //深拷贝
        // chartScale.maxLength = cuverData.end_pos;
        // chartScale.minLength = cuverData.start_pos;

        getMaxMin(echartsGroupDataSort);
      }

      //改用百度echats
      initCurverEchartsFun(echartsGroupData);
    };

    const throttle = (left, top, interval = 300) => {
      if (!moveFlag.value) return;
      moveFlag.value = false;
      setTimeout(() => {
        tooltipStyle.left = left - 250 + "px";
        tooltipStyle.top = 0;
        moveFlag.value = true;
      }, interval);
    };

    const fixHoverPoineDataEcharts = (params) => {
      let selectObj = [];
      let pointLength = parseInt(params.value[0]); //不能四舍五入
      selectObj = harmList.filter(
        (v) => pointLength == parseInt(v.damageSiteO) && v.ropeNum == params.seriesIndex + 1
      );

      return selectObj;
    };

    const filterDamageLevelText = (status) => {
      return filterDamageLevelTextHook(status);
    };

    const filterDamageLevelColor = (status) => {
      return filterDamageLevelColorHook(status);
    };

    // 百度echarts line 初始化
    const initCurverEchartsFun = (echartsRopeData) => {
      if (echartsObj == null) {
        echartsObj = echarts.init(document.querySelector("#curverEcharts"));
      }

      const options = initCurverSencorEchatsOptionsDetail(
        echartsRopeData,
        chartScale
      );
      echartsObj.setOption(options);

      echartsObj.on("mousemove", "series", function (params) {
        tooTipVis.value = true;
        let left = params.event.offsetX;
        let top = params.event.offsetY;
        throttle(left, top);
        hoverPoineData.pointDataArr = fixHoverPoineDataEcharts(params);
        hoverPoineData.pointLength = params.value;
      });

      echartsObj.on("mouseout", "series", function () {
        tooTipVis.value = false;
      });
    };

    watch(
      () => props.id,
      (id) => {
        nextTick(() => {
          initEchartsCurveById(id);
        });
      },
      { deep: true, immediate: true }
    );

    watch(
      () => props.cuverFlag,
      (flag) => {
        dialogVisible.value = flag;
        if (!flag) return;
        setTimeout(() => {
          nextTick(() => {
  
          });
        }, 500);
      },
      { deep: true, immediate: true }
    );

    watch(
      () => props.resetNum,
      () => {
        if (!props.cuverFlag) {
          echartsObj.clear();
          initCurverEchartsFun(echartsGroupData);
        }
      },
      { deep: true, immediate: false }
    );

    watch(
      () => props.zoomScaleY,
      (flag) => {
        chartScale.zoomScaleY = flag;
        initCurverEchartsFun(echartsGroupData);
      },
      { deep: true, immediate: false }
    );

    watch(
      () => props.flawListData,
      (flawList) => {
        harmList.push(...flawList);
      },
      { deep: true, immediate: false }
    );

    watch(
      () => store.state.newTimes,
      (newTimes, oldnewTimes) => {
        echartsObj && echartsObj.clear();
        if (oldnewTimes > 0) {
          initEchartsCurveById(props.id);
        }
      },
      { deep: true, immediate: false }
    );

    const closeSensorDlalog = () => {
      context.emit("changeCuverFlagVis", false);
    };

    const getMaxMin = (groupDataSort) => {
      let lengthSort = groupDataSort[0].sort((a, b) => b[0] - a[0]);
      chartScale.maxLength = lengthSort[0][0];
      chartScale.minLength = lengthSort[lengthSort.length - 1][0];

      let valueSort = groupDataSort[groupDataSort.length - 1].sort(
        (a, b) => b[1] - a[1]
      );
      chartScale.maxValue = Math.floor(valueSort[0][1] + 100);
      let valueSortMin = groupDataSort[0].sort((a, b) => b[1] - a[1]);
      chartScale.minValue = Math.floor(
        valueSortMin[valueSortMin.length - 1][1] - 100
      );
    };

    onMounted(() => {
      nextTick(() => {
        window.addEventListener(
          "resize",
          debounce(() => {
            echartsObj.resize();
            // echartsObj.setOption(optionsConfig.option);
          })
        );
      });
    });

    onBeforeUnmount(() => {
      isDestory.value = true;
      if (chart) chart.destroy();
      if (sencorChart) sencorChart.destroy();
    });

    return {
      chartRef,

      imgSrc,
      dialogVisible,
      isDestory,
      sensorsNum,
      harmList,
      tooltipStyle,
      tooTipVis,
      curverEcharts,
      echartsSencorData,
      echartsObj,
      ...toRefs(hoverPoineData),
      closeSensorDlalog,
      filterDamageLevelText,
      filterDamageLevelColor,

      //filterCuverData
    };
  },
  data() {
    return {};
  },
  props: {
    id: [Number, String],
    resetNum: [Number, String],
    cuverFlag: Boolean,
    zoomScaleY: Boolean,
    flawListData:Array
  },
  components: {
    waveSensor,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.wave-box {
  position: relative;
  width: 100%;
  .rope-charts {
    .wave-antv {
      // width: 99%;
      height: 5.25rem;
      // height: 6.25rem;
      margin: 0.25rem auto;
      padding: 0.125rem 0;
      //  background-color: #021E33;
      // box-shadow: 0 0 2px #FFF;
      border-radius: 2px;
    }
  }
  .sensor-box {
    width: 100%;
    //  height: 6.5rem;
    //  overflow-y: scroll;
    .sensor-antv {
      width: 90%;
      // height: 5.625rem;
      height: 6rem;
      margin: 20px auto;
      padding: 20px;
      background-color: #f0f0f0;
    }
  }
  & :deep .el-dialog {
    background-color: #021e33;
  }
  & :deep .el-dialog__title {
    color: #fff;
  }
  .tooltip-box {
    background-color: #031f35;
    position: absolute;
    width: 3.125rem;
    left: 0.375rem;
    top: 0.625rem;
    font-size: 0.1625rem;
    color: #fff;
    padding: 0.0625rem;
    ul {
      li {
        margin-bottom: 0.0625rem;
        p {
          display: flex;
          justify-content: space-between;
          padding: 0.0625rem;
          span {
            display: block;
          }
        }
        .flaw-box {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
