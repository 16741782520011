<template>
  <div class="sensor-box">
    <div class="handle-box">
      <el-button type="success" size="small" @click="resetAntvRender">还原</el-button>
      <el-checkbox
        v-model="chartScale.zoomScaleY"
        label="Y轴缩放"
        size="large"
        style="margin-left: 10px"
      />
    </div>
    <!-- <div id="container_sensor1" class="sensor-antv"></div> -->
    <div id="sensorEcharts" ref="curverEcharts" class="sensor-echarts"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { nextTick, onMounted, watch, onBeforeUnmount, reactive } from "vue";
// import { Chart } from "@antv/g2";
// import { getSencorAntvG2Charts } from "@/hooks/detail/antv/cuver/sensorCharts.js"; //引入封装的Antv G2 组件
import { initCurverSencorEchatsOptions } from "@/hooks/detail/echarts/curver/curverEcharts.js";
export default {
  name: "",
  setup(props) {
    let sencorChartNew = null;
    let echartsSensorObj = null;

    let chartScale = reactive({
      maxLength: 0,
      minLength: 0,
      maxValue: 0,
      minValue: 0,
      zoomScaleY: false,
    });

    // const initSencorAntvG2Charts = () => {
    //   sencorChartNew = new Chart({
    //     container: document.querySelector("#container_sensor1"),
    //     autoFit: true,
    //     // defaultInteractions: [],
    //   });
    // };

    const resetAntvRender = () => {
      echartsSensorObj.clear();
      const options = initCurverSencorEchatsOptions(
        props.echartsSencorData,
        chartScale
      );
      echartsSensorObj.setOption(options);
    };

    const initCurverEchartsFun = () => {
      echartsSensorObj = echarts.init(document.querySelector("#sensorEcharts"));
      const options = initCurverSencorEchatsOptions(
        props.echartsSencorData,
        chartScale,
        false
      );
      echartsSensorObj.setOption(options);
    };

    const getMaxMin = (echartsSencorData) => {
      let sortLength = echartsSencorData[0].map((v) => v[0]);
      const sencorDataSort = sortLength.sort((a, b) => b - a);
      chartScale.maxLength = sencorDataSort[0];
      chartScale.minLength = sencorDataSort[sencorDataSort.length - 1];

      let sortValueMax = echartsSencorData[echartsSencorData.length - 1].map(
        (v) => v[1]
      );
      sortValueMax = sortValueMax.sort((a, b) => b - a);
      chartScale.maxValue = sortValueMax[0] + 100;
      let sortValueMin = echartsSencorData[0].map((v) => v[1]);
      sortValueMin = sortValueMin.sort((a, b) => b - a);
      chartScale.minValue = sortValueMin[sortValueMin.length-1]-100;

    };

    watch(
      () => chartScale.zoomScaleY,
      () => {
        const options = initCurverSencorEchatsOptions(
          props.echartsSencorData,
          chartScale
        );
        echartsSensorObj.setOption(options);
      },
      { deep: true, immediate: false }
    );

    watch(
      () => props.isDestory,
      () => {
        //sencorChartNew.destroy();
      },
      { deep: true, immediate: false }
    );

    onMounted(() => {
      nextTick(() => {
        setTimeout(() => {
          if (!props.echartsSencorData) return;
          const sortSencorData = [...props.echartsSencorData];
          getMaxMin(sortSencorData);
          initCurverEchartsFun(props.echartsSencorData);
        }, 500);
      });
    });

    onBeforeUnmount(() => {});

    return {
      chartScale,
      sencorChartNew,
      resetAntvRender,
    };
  },
  data() {
    return {};
  },

  mounted() {},

  props: {
    echartsSencorData: Array,
    isDestory: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.handle-box{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;

}
.sensor-antv,
.sensor-echarts {
  width: 90%;
  // height: 5.625rem;
  height: 10rem;
  margin: 20px auto;
  padding: 20px;
  // background-color: #f0f0f0;
}
</style>